// 動きのきっかけの起点となるアニメーションの名前を定義
function fadeAnime() {
  // ふわっ
  jQuery(".fadeInTrigger").each(function() {
    //fadeInTriggerというクラス名が
    var elemPos = jQuery(this).offset().top - 25; //要素より、25px上の
    var scroll = jQuery(window).scrollTop();
    var windowHeight = jQuery(window).height();
    if (scroll >= elemPos - windowHeight) {
      jQuery(this).addClass("fadeIn"); // 画面内に入ったらfadeInというクラス名を追記
    } else {
      jQuery(this).removeClass("fadeIn"); // 画面外に出たらfadeInというクラス名を外す
    }
  });

  jQuery(".fadeUpTrigger").each(function() {
    //fadeUpTriggerというクラス名が
    var elemPos = jQuery(this).offset().top - 25; //要素より、25px上の
    var scroll = jQuery(window).scrollTop();
    var windowHeight = jQuery(window).height();
    if (scroll >= elemPos - windowHeight) {
      jQuery(this).addClass("fadeUp"); // 画面内に入ったらfadeUpというクラス名を追記
    } else {
      jQuery(this).removeClass("fadeUp"); // 画面外に出たらfadeUpというクラス名を外す
    }
  });

  jQuery(".fadeDownTrigger").each(function() {
    //fadeDownTriggerというクラス名が
    var elemPos = jQuery(this).offset().top - 25; //要素より、25px上の
    var scroll = jQuery(window).scrollTop();
    var windowHeight = jQuery(window).height();
    if (scroll >= elemPos - windowHeight) {
      jQuery(this).addClass("fadeDown"); // 画面内に入ったらfadeDownというクラス名を追記
    } else {
      jQuery(this).removeClass("fadeDown"); // 画面外に出たらfadeDownというクラス名を外す
    }
  });

  jQuery(".fadeLeftTrigger").each(function() {
    //fadeLeftTriggerというクラス名が
    var elemPos = jQuery(this).offset().top - 25; //要素より、25px上の
    var scroll = jQuery(window).scrollTop();
    var windowHeight = jQuery(window).height();
    if (scroll >= elemPos - windowHeight) {
      jQuery(this).addClass("fadeLeft"); // 画面内に入ったらfadeLeftというクラス名を追記
    } else {
      jQuery(this).removeClass("fadeLeft"); // 画面外に出たらfadeLeftというクラス名を外す
    }
  });

  jQuery(".fadeRightTrigger").each(function() {
    //fadeRightTriggerというクラス名が
    var elemPos = jQuery(this).offset().top - 25; //要素より、25px上の
    var scroll = jQuery(window).scrollTop();
    var windowHeight = jQuery(window).height();
    if (scroll >= elemPos - windowHeight) {
      jQuery(this).addClass("fadeRight"); // 画面内に入ったらfadeRightというクラス名を追記
    } else {
      jQuery(this).removeClass("fadeRight"); // 画面外に出たらfadeRightというクラス名を外す
    }
  });

  // パタッ

  jQuery(".flipDownTrigger").each(function() {
    //flipDownTriggerというクラス名が
    var elemPos = jQuery(this).offset().top - 25; //要素より、25px上の
    var scroll = jQuery(window).scrollTop();
    var windowHeight = jQuery(window).height();
    if (scroll >= elemPos - windowHeight) {
      jQuery(this).addClass("flipDown"); // 画面内に入ったらflipDownというクラス名を追記
    } else {
      jQuery(this).removeClass("flipDown"); // 画面外に出たらflipDownというクラス名を外す
    }
  });

  jQuery(".flipLeftTrigger").each(function() {
    //flipLeftTriggerというクラス名が
    var elemPos = jQuery(this).offset().top - 25; //要素より、25px上の
    var scroll = jQuery(window).scrollTop();
    var windowHeight = jQuery(window).height();
    if (scroll >= elemPos - windowHeight) {
      jQuery(this).addClass("flipLeft"); // 画面内に入ったらflipLeftというクラス名を追記
    } else {
      jQuery(this).removeClass("flipLeft"); // 画面外に出たらflipLeftというクラス名を外す
    }
  });

  jQuery(".flipLeftTopTrigger").each(function() {
    //flipLeftTopTriggerというクラス名が
    var elemPos = jQuery(this).offset().top - 25; //要素より、25px上の
    var scroll = jQuery(window).scrollTop();
    var windowHeight = jQuery(window).height();
    if (scroll >= elemPos - windowHeight) {
      jQuery(this).addClass("flipLeftTop"); // 画面内に入ったらflipLeftTopというクラス名を追記
    } else {
      jQuery(this).removeClass("flipLeftTop"); // 画面外に出たらflipLeftTopというクラス名を外す
    }
  });

  jQuery(".flipRightTrigger").each(function() {
    //flipRightTriggerというクラス名が
    var elemPos = jQuery(this).offset().top - 25; //要素より、25px上の
    var scroll = jQuery(window).scrollTop();
    var windowHeight = jQuery(window).height();
    if (scroll >= elemPos - windowHeight) {
      jQuery(this).addClass("flipRight"); // 画面内に入ったらflipRightというクラス名を追記
    } else {
      jQuery(this).removeClass("flipRight"); // 画面外に出たらflipRightというクラス名を外す
    }
  });

  jQuery(".flipRightTopTrigger").each(function() {
    //flipRightTopTriggerというクラス名が
    var elemPos = jQuery(this).offset().top - 25; //要素より、25px上の
    var scroll = jQuery(window).scrollTop();
    var windowHeight = jQuery(window).height();
    if (scroll >= elemPos - windowHeight) {
      jQuery(this).addClass("flipRightTop"); // 画面内に入ったらflipRightTopというクラス名を追記
    } else {
      jQuery(this).removeClass("flipRightTop"); // 画面外に出たらflipRightTopというクラス名を外す
    }
  });

  // くるっ

  jQuery(".rotateXTrigger").each(function() {
    //rotateXTriggerというクラス名が
    var elemPos = jQuery(this).offset().top - 25; //要素より、25px上の
    var scroll = jQuery(window).scrollTop();
    var windowHeight = jQuery(window).height();
    if (scroll >= elemPos - windowHeight) {
      jQuery(this).addClass("rotateX"); // 画面内に入ったらrotateXというクラス名を追記
    } else {
      jQuery(this).removeClass("rotateX"); // 画面外に出たらrotateXというクラス名を外す
    }
  });

  jQuery(".rotateYTrigger").each(function() {
    //rotateYTriggerというクラス名が
    var elemPos = jQuery(this).offset().top - 25; //要素より、25px上の
    var scroll = jQuery(window).scrollTop();
    var windowHeight = jQuery(window).height();
    if (scroll >= elemPos - windowHeight) {
      jQuery(this).addClass("rotateY"); // 画面内に入ったらrotateYというクラス名を追記
    } else {
      jQuery(this).removeClass("rotateY"); // 画面外に出たらrotateYというクラス名を外す
    }
  });

  jQuery(".rotateLeftZTrigger").each(function() {
    //rotateLeftZTriggerというクラス名が
    var elemPos = jQuery(this).offset().top - 25; //要素より、25px上の
    var scroll = jQuery(window).scrollTop();
    var windowHeight = jQuery(window).height();
    if (scroll >= elemPos - windowHeight) {
      jQuery(this).addClass("rotateLeftZ"); // 画面内に入ったらrotateLeftZというクラス名を追記
    } else {
      jQuery(this).removeClass("rotateLeftZ"); // 画面外に出たらrotateLeftZというクラス名を外す
    }
  });

  jQuery(".rotateRightZTrigger").each(function() {
    //rotateRightZTriggerというクラス名が
    var elemPos = jQuery(this).offset().top - 25; //要素より、25px上の
    var scroll = jQuery(window).scrollTop();
    var windowHeight = jQuery(window).height();
    if (scroll >= elemPos - windowHeight) {
      jQuery(this).addClass("rotateRightZ"); // 画面内に入ったらrotateRightZというクラス名を追記
    } else {
      jQuery(this).removeClass("rotateRightZ"); // 画面外に出たらrotateRightZというクラス名を外す
    }
  });

  // ボンッ

  jQuery(".zoomInTrigger").each(function() {
    //zoomInTriggerというクラス名が
    var elemPos = jQuery(this).offset().top - 25; //要素より、25px上の
    var scroll = jQuery(window).scrollTop();
    var windowHeight = jQuery(window).height();
    if (scroll >= elemPos - windowHeight) {
      jQuery(this).addClass("zoomIn"); // 画面内に入ったらzoomInというクラス名を追記
    } else {
      jQuery(this).removeClass("zoomIn"); // 画面外に出たらzoomInというクラス名を外す
    }
  });

  jQuery(".zoomInLeftTrigger").each(function() {
    //zoomInTriggerというクラス名が
    var elemPos = jQuery(this).offset().top - 25; //要素より、25px上の
    var scroll = jQuery(window).scrollTop();
    var windowHeight = jQuery(window).height();
    if (scroll >= elemPos - windowHeight) {
      jQuery(this).addClass("zoomInLeft"); // 画面内に入ったらzoomInというクラス名を追記
    } else {
      jQuery(this).removeClass("zoomInLeft"); // 画面外に出たらzoomInというクラス名を外す
    }
  });

  jQuery(".zoomInRightTrigger").each(function() {
    //zoomInTriggerというクラス名が
    var elemPos = jQuery(this).offset().top - 25; //要素より、25px上の
    var scroll = jQuery(window).scrollTop();
    var windowHeight = jQuery(window).height();
    if (scroll >= elemPos - windowHeight) {
      jQuery(this).addClass("zoomInRight"); // 画面内に入ったらzoomInというクラス名を追記
    } else {
      jQuery(this).removeClass("zoomInRight"); // 画面外に出たらzoomInというクラス名を外す
    }
  });

  // ヒュッ

  jQuery(".zoomOutTrigger").each(function() {
    //zoomOutTriggerというクラス名が
    var elemPos = jQuery(this).offset().top - 25; //要素より、25px上の
    var scroll = jQuery(window).scrollTop();
    var windowHeight = jQuery(window).height();
    if (scroll >= elemPos - windowHeight) {
      jQuery(this).addClass("zoomOut"); // 画面内に入ったらzoomOutというクラス名を追記
    } else {
      jQuery(this).removeClass("zoomOut"); // 画面外に出たらzoomOutというクラス名を外す
    }
  });

  // じわっ

  jQuery(".blurTrigger").each(function() {
    //blurTriggerというクラス名が
    var elemPos = jQuery(this).offset().top - 25; //要素より、25px上の
    var scroll = jQuery(window).scrollTop();
    var windowHeight = jQuery(window).height();
    if (scroll >= elemPos - windowHeight) {
      jQuery(this).addClass("blur"); // 画面内に入ったらblurというクラス名を追記
    } else {
      jQuery(this).removeClass("blur"); // 画面外に出たらblurというクラス名を外す
    }
  });

  // にゅーん

  jQuery(".smoothTrigger").each(function() {
    //smoothTriggerというクラス名が
    var elemPos = jQuery(this).offset().top - 25; //要素より、25px上の
    var scroll = jQuery(window).scrollTop();
    var windowHeight = jQuery(window).height();
    if (scroll >= elemPos - windowHeight) {
      jQuery(this).addClass("smooth"); // 画面内に入ったらsmoothというクラス名を追記
    } else {
      jQuery(this).removeClass("smooth"); // 画面外に出たらsmoothというクラス名を外す
    }
  });

  // スーッ（枠線が伸びて出現）

  jQuery(".lineTrigger").each(function() {
    //lineTriggerというクラス名が
    var elemPos = jQuery(this).offset().top - 25; //要素より、25px上の
    var scroll = jQuery(window).scrollTop();
    var windowHeight = jQuery(window).height();
    if (scroll >= elemPos - windowHeight) {
      jQuery(this).addClass("lineanime"); // 画面内に入ったらlineanimeというクラス名を追記
    } else {
      jQuery(this).removeClass("lineanime"); // 画面外に出たらlineanimeというクラス名を外す
    }
  });

  // シャッ（背景色が伸びて出現）

  jQuery(".bgLRextendTrigger").each(function() {
    //bgLRextendTriggerというクラス名が
    var elemPos = jQuery(this).offset().top - 25; //要素より、25px上の
    var scroll = jQuery(window).scrollTop();
    var windowHeight = jQuery(window).height();
    if (scroll >= elemPos - windowHeight) {
      jQuery(this).addClass("bgLRextend"); // 画面内に入ったらbgLRextendというクラス名を追記
    } else {
      jQuery(this).removeClass("bgLRextend"); // 画面外に出たらbgLRextendというクラス名を外す
    }
  });

  jQuery(".bgRLextendTrigger").each(function() {
    //bgRLextendTriggerというクラス名が
    var elemPos = jQuery(this).offset().top - 25; //要素より、25px上の
    var scroll = jQuery(window).scrollTop();
    var windowHeight = jQuery(window).height();
    if (scroll >= elemPos - windowHeight) {
      jQuery(this).addClass("bgRLextend"); // 画面内に入ったらbgRLextendというクラス名を追記
    } else {
      jQuery(this).removeClass("bgRLextend"); // 画面外に出たらbgRLextendというクラス名を外す
    }
  });

  jQuery(".bgDUextendTrigger").each(function() {
    //bgDUextendTriggerというクラス名が
    var elemPos = jQuery(this).offset().top - 25; //要素より、25px上の
    var scroll = jQuery(window).scrollTop();
    var windowHeight = jQuery(window).height();
    if (scroll >= elemPos - windowHeight) {
      jQuery(this).addClass("bgDUextend"); // 画面内に入ったらbgDUextendというクラス名を追記
    } else {
      jQuery(this).removeClass("bgDUextend"); // 画面外に出たらbgDUextendというクラス名を外す
    }
  });

  jQuery(".LR_Trigger").each(function() {
    //bgUDextendTriggerというクラス名が
    var elemPos = jQuery(this).offset().top - 25; //要素より、25px上の
    var scroll = jQuery(window).scrollTop();
    var windowHeight = jQuery(window).height();
    if (scroll >= elemPos - windowHeight) {
      jQuery(this).addClass("LR"); // 画面内に入ったらLRというクラス名を追記
    } else {
      jQuery(this).removeClass("LR"); // 画面外に出たらLRというクラス名を外す
    }
  });

  jQuery(".TB_Trigger").each(function() {
    //bgUDextendTriggerというクラス名が
    var elemPos = jQuery(this).offset().top - 25; //要素より、25px上の
    var scroll = jQuery(window).scrollTop();
    var windowHeight = jQuery(window).height();
    if (scroll >= elemPos - windowHeight) {
      jQuery(this).addClass("TB"); // 画面内に入ったらLRというクラス名を追記
    } else {
      jQuery(this).removeClass("TB"); // 画面外に出たらLRというクラス名を外す
    }
  });

  jQuery(".bgappearTrigger").each(function() {
    //bgappearTriggerというクラス名が
    var elemPos = jQuery(this).offset().top - 25; //要素より、25px上の
    var scroll = jQuery(window).scrollTop();
    var windowHeight = jQuery(window).height();
    if (scroll >= elemPos - windowHeight) {
      jQuery(this).addClass("bgappear"); // 画面内に入ったらbgappearというクラス名を追記
    } else {
      jQuery(this).removeClass("bgappear"); // 画面外に出たらbgappearというクラス名を外す
    }
  });

  jQuery(".bgUDextendTrigger").each(function() {
    //bgUDextendTriggerというクラス名が
    var elemPos = jQuery(this).offset().top - 25; //要素より、25px上の
    var scroll = jQuery(window).scrollTop();
    var windowHeight = jQuery(window).height();
    if (scroll >= elemPos - windowHeight) {
      jQuery(this).addClass("bgUDextend"); // 画面内に入ったらbgUDextendというクラス名を追記
    } else {
      jQuery(this).removeClass("bgUDextend"); // 画面外に出たらbgUDextendというクラス名を外す
    }
  });

  jQuery(".flowingTrigger").each(function() {
    //flowingというクラス名が
    var elemPos = jQuery(this).offset().top - 25; //要素より、25px上の
    var scroll = jQuery(window).scrollTop();
    var windowHeight = jQuery(window).height();
    if (scroll >= elemPos - windowHeight) {
      jQuery(this).addClass("flowing"); // 画面内に入ったら.flowingというクラス名を追記
    } else {
      jQuery(this).removeClass("flowing"); // 画面外に出たら.flowingというクラス名を外す
    }
  });
}

// 画面をスクロールをしたら動かしたい場合の記述
jQuery(window).scroll(function() {
  fadeAnime();
}); // ここまで画面をスクロールをしたら動かしたい場合の記述

/* // 画面が読み込まれたらすぐに動かしたい場合の記述
jQuery(window).on("load", function() {
  fadeAnime(); 
}); 
// ここまで画面が読み込まれたらすぐに動かしたい場合の記述 */
